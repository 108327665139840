import * as React from "react"

export default function SiteInternalLink(props) {
  const { href, link = {}, ...rest } = props

  return (
    <a {...rest} href={href} target="_self">
      {props.children}
    </a>
  )
}

import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export default function SiteImage ({ src, ...props }) {
  return (
    <img
      src={src}
      {...props}
    />
  )
}

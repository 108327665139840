import { SITE_URL } from '../../../../constants'

export const DEFAULT_SITE_CONTACT = `${SITE_URL}/contact`;
export const DEFAULT_SITE_MENU_ITEMS = [
  {
    name: 'Testimonials',
    url: `${SITE_URL}/#testimonials`
  },
  {
    name: 'About Us',
    url: `${SITE_URL}/about-us`
  },
  {
    name: 'Blog',
    url: '/'
  },
  {
    name: 'Portfolio',
    url: `${SITE_URL}/portfolio`
  },
  {
    name: 'Career',
    url: `${SITE_URL}/career`
  },
];

export const DEFAULT_SITE_SERVICES_MENU_ITEMS = [
  {
    name: 'Web Development',
    url: `${SITE_URL}/services/web-development`
  },
  {
    name: 'IT Talent Solutions',
    url: `${SITE_URL}/services/hiring-solutions`
  },
  {
    name: 'How We Hire',
    url: `${SITE_URL}/services/how-we-hire`
  },
  {
    name: 'Our Technologies',
    url: `${SITE_URL}/services/our-technologies`
  },
]

// extracted by mini-css-extract-plugin
export var hidden = "styles-module--hidden--Uc3uk";
export var menu = "styles-module--menu--jac4q";
export var buttonTalk = "styles-module--buttonTalk--T9l2k";
export var servicesDropdownToggle = "styles-module--servicesDropdownToggle--wi+0c";
export var iconRight = "styles-module--iconRight--gWGq5";
export var open = "styles-module--open--tRt4U";
export var dropdownMenu = "styles-module--dropdownMenu--RtcR9";
export var dropdownItem = "styles-module--dropdownItem--Q55ys";
export var navItem = "styles-module--navItem--+X8k9";
export var active = "styles-module--active--txK3V";
export var menuIcon = "styles-module--menuIcon--cypy9";
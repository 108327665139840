// extracted by mini-css-extract-plugin
export var button = "styles-module--button--PdzMq";
export var variant_disabled = "styles-module--variant_disabled--lgwB9";
export var variant_link = "styles-module--variant_link--dUy96";
export var variant_primary = "styles-module--variant_primary--kZYPN";
export var variant_dark = "styles-module--variant_dark--4ade3";
export var variant_icon = "styles-module--variant_icon--ZWaoc";
export var variant_secondary = "styles-module--variant_secondary--i6S0Y";
export var variant_focusScale = "styles-module--variant_focus-scale--FphQy";
export var variant_focusGradient = "styles-module--variant_focus-gradient--3D2D8";
export var iconLeft = "styles-module--iconLeft--G4ito";
export var iconRight = "styles-module--iconRight--guOHk";
export var as_a = "styles-module--as_a--q3Jy2";
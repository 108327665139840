// extracted by mini-css-extract-plugin
export var header = "styles-module--header--HJVUF";
export var menuOpen = "styles-module--menuOpen--Sl02K";
export var menuToggle = "styles-module--menuToggle--+mt8J";
export var close = "styles-module--close--VDLzo";
export var variant_light = "styles-module--variant_light--LjhcO";
export var open = "styles-module--open--Ex459";
export var variant_transparent = "styles-module--variant_transparent--E3rR3";
export var variant_dark = "styles-module--variant_dark--BVYrR";
export var variant_scroll = "styles-module--variant_scroll--Sdjqg";
export var variant_solid = "styles-module--variant_solid--45VrU";
export var variant_darkBg = "styles-module--variant_dark-bg--XZi2R";
export var variant_lightBg = "styles-module--variant_light-bg--Ibt29";
export var bodyMenuOpen = "styles-module--bodyMenuOpen--nOkNB";
export var rootWithNavBar = "styles-module--rootWithNavBar--OBnMM";
export var hidden = "styles-module--hidden--W7C67";
export var initialized = "styles-module--initialized--pB3f6";
export var atTop = "styles-module--atTop--bITit";
export var hideMenu = "styles-module--hideMenu--8vMmS";
export var logoLink = "styles-module--logoLink--a4nm8";
export var menu = "styles-module--menu--GRO4j";
export var nav = "styles-module--nav--kK7zl";
export var navbarWrapper = "styles-module--navbarWrapper--Ft63E";
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SiteNavBar from '../components/shared/site-navbar'
import * as styles from './styles.module.scss'

import GoUp from "./common/goUp"
const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <div className="flex flex-col justify-between">
       <SiteNavBar
          variant={['solid', 'dark']}
          variantAtScrollTop={['solid', 'dark']}
        />
        <main className={styles.layoutDefaultMainContent + ' flex-1'}>{children}</main>
        <GoUp />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import * as React from "react"

import { forwardRef, useCallback, useMemo, useState, useEffect } from 'react'
import classnames from 'classnames'

import SiteImage from '../site-image'
import SiteInternalLink from '../site-internal-link'
import SiteLogo from '../site-logo'
import SiteMainMenu from './site-main-menu'

import useOverlappingObserver from '../../../utils/use_overlapping_observer'
import useElementClass from '../../../utils/use_element_class'
import useVariants, { combineVariants, isVariant } from '../../../utils/use_variants'
import useCombinedRefs from '../../../utils/use_combined_refs'
import useMediaQuery from '../../../utils/use_media_query'

import { forDesktopUp, forTabletDown } from '../../../styles/shared/media_queries.scss'

import CloseX from '../../../assets/shared/nav_bar/close_x.svg'
import HamburguerMenu from '../../../assets/shared/nav_bar/hamburguer_menu.svg'
import HamburguerMenuWhite from '../../../assets/shared/nav_bar/hamburguer_menu_white.svg'

import * as classes from "./styles.module.scss"
import { SITE_URL } from "../../../constants"

function SiteBaseNavbar ({
  variant,
  variantAtScrollTop,
  show = true,
  pathLogo = '/',
  hideMenu = false,
  contactPagePath = '/contact'
}, ref) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [atScrollTop, observerRef] = useOverlappingObserver({
    root: globalThis.document?.body,
    ignoreHeight: true,
    defaultValue: null
  })
  const [initialized, setInitialized] = useState(false)
  const containerRef = useCombinedRefs(ref, observerRef)
  const isDesktopUp = useMediaQuery(forDesktopUp)
  const isTabletDown = useMediaQuery(forTabletDown)

  useEffect(() => {
    if (atScrollTop !== null) setInitialized(true)
  }, [atScrollTop])

  variant = useMemo(() => {
    if (atScrollTop !== false && variantAtScrollTop) return variantAtScrollTop

    return variant
  }, [atScrollTop, variant, variantAtScrollTop])
  variant = useMemo(() => {
    if (atScrollTop !== false) return variant

    return combineVariants(variant, 'scroll')
  }, [atScrollTop, variant])

  const logoVariant = useMemo(() => {
    let logoVariant

    if (isTabletDown) logoVariant = combineVariants(logoVariant, 'isologo')
    else logoVariant = combineVariants(logoVariant, 'full')

    if (!menuOpen && isVariant(variant, 'light')) {
      logoVariant = combineVariants(logoVariant, 'white')
    } else {
      logoVariant = combineVariants(logoVariant, 'color')
    }

    return logoVariant
  }, [variant, isTabletDown, menuOpen])

  const variantClassNames = useVariants(classes, variant, {
    prefix: 'variant_',
    defaults: {
      solid: variants => !isVariant(variants, 'transparent'),
      dark: variants => !isVariant(variants, 'light'),
      'light-bg': variants => !isVariant(variants, 'dark-bg')
    }
  })
  const toggleMenu = useCallback(() => {
    setMenuOpen(v => !v)
  }, [])
  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  useElementClass(globalThis.document?.getElementById('__next'), classes.rootWithNavBar)
  useElementClass(globalThis.document?.body, classnames({ [classes.bodyMenuOpen]: menuOpen }))

  return (
    <header
      ref={containerRef}
      aria-hidden={!show || !initialized}
      className={classnames(classes.header, variantClassNames, {
        [classes.menuOpen]: menuOpen,
        [classes.atTop]: atScrollTop,
        [classes.hideMenu]: hideMenu,
        [classes.hidden]: !show && initialized,
        [classes.initialized]: initialized
      })}
    >
      <div className={classes.navbarWrapper + ' container'}>
        <a
          href={SITE_URL}
          className={classes.logoLink}
        >
          <SiteLogo
            variant={logoVariant}
            height='40'
            loading='eager'
          />
        </a>
          {!hideMenu && (
            <nav className={classes.nav}>
              <button
                type='button'
                onClick={toggleMenu}
                className={classes.menuToggle}
              >
                {menuOpen && <SiteImage src={CloseX} width='24' height='24' alt='Open menu' />}
                {!menuOpen &&
                  <SiteImage
                    src={isVariant(variant, 'light') && isVariant(variant, 'transparent') ? HamburguerMenuWhite : HamburguerMenu}

                    width='27'
                    height='27'
                    alt='Open menu'
                  />}
              </button>
              <SiteMainMenu
                isOpen={isDesktopUp || menuOpen}
                onRequestClose={closeMenu}
                className={classes.menu}
                variant={variant}
                contactPagePath={contactPagePath}
              />
            </nav>)}
      </div>
    </header>
  )
}

const SiteNavbar = forwardRef(SiteBaseNavbar)
export default SiteNavbar

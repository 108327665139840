import * as React from 'react'
import { useMemo, useCallback, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import classNames from 'classnames'

import SiteButton from '../../site-button'
import SiteInternalLink from '../../site-internal-link'
import SiteImage from '../../site-image'

import useMediaQuery from '../../../../utils/use_media_query'
import { isVariant } from '../../../../utils/use_variants'
import { DEFAULT_SITE_MENU_ITEMS, DEFAULT_SITE_SERVICES_MENU_ITEMS, DEFAULT_SITE_CONTACT } from './constants'
import { forTabletDown } from '../../../../styles/shared/media_queries.scss'

import DropdownIcon from '../../../../assets/shared/nav_bar/dropdown_icon.svg'
import DropdownIconWhite from '../../../../assets/shared/nav_bar/dropdown_icon_white.svg'

import * as classes from "./styles.module.scss"

function SiteMainMenu ({
  isOpen,
  onRequestClose,
  className,
  variant,
  contactPagePath = '/contact'
}) {
  const isTabletDown = useMediaQuery(forTabletDown)
  const dropdownIconURL = isVariant(variant, 'light') && !isTabletDown ? DropdownIconWhite : DropdownIcon
  const contactCTAVariant = useMemo(() => {
    if (isTabletDown) return ['primary', 'dark']
    if (isVariant(variant, 'transparent') && isVariant(variant, 'light')) {
      return ['secondary', 'dark']
    } else if (isVariant(variant, 'transparent') && !isVariant(variant, 'light')) {
      return ['secondary', 'light']
    } else if (isVariant('light')) {
      return ['primary', 'dark']
    }

    return ['primary', 'light']
  }, [variant, isTabletDown])
  const handleClick = useCallback((e) => {
    const buttonOrAnchor = e.target.closest('a') ?? e.target.closest('button')

    if (buttonOrAnchor !== null && buttonOrAnchor.dataset.closeMenuOnClick !== 'false') {
      onRequestClose()
    }
  }, [onRequestClose])
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false)

  return (
    <ul onClick={handleClick} className={classNames(classes.menu, className, { [classes.hidden]: !isOpen })}>
      <li
        className={classNames(classes.navItem, {
          [classes.active]: globalThis.window?.location.pathname.startsWith('/services')
        })}
      >
        <Dropdown show={isServicesDropdownOpen} onToggle={setIsServicesDropdownOpen} >
          <Dropdown.Toggle
            as={SiteButton}
            variant='link'
            className={classNames(classes.servicesDropdownToggle, {
              [classes.open]: isServicesDropdownOpen
            })}
            data-close-menu-on-click='false'
            iconRight={
              <SiteImage
                src={dropdownIconURL}
                className={classes.iconRight}
              />
            }
          >
            Services
          </Dropdown.Toggle>
          <Dropdown.Menu className={classes.dropdownMenu} renderOnMount>
            {DEFAULT_SITE_SERVICES_MENU_ITEMS.map((childMenuItem, i) => {
              return (
                <Dropdown.Item
                  key={i}
                  as={SiteInternalLink}
                  href={childMenuItem.url}
                  className={classes.dropdownItem}
                >
                  {childMenuItem.name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </li>
      {DEFAULT_SITE_MENU_ITEMS.map((menuItem, i) => (
        <li key={i} className={classNames(classes.navItem, {
          [classes.active]: globalThis.window?.location.pathname.startsWith('/about-us')
        })}>
        <a href={menuItem.url} target='_self' rel='noopener noreferrer'>
          {menuItem.name}
        </a>
      </li>
      ))}
      <li>
        <SiteButton
          as={SiteInternalLink}
          isAnchor
          variant={contactCTAVariant}
          href={DEFAULT_SITE_CONTACT}
          className={classes.buttonTalk}
          onClick={onRequestClose}
          link={{ scroll: false, shallow: true }}
        >
          Get in Touch
        </SiteButton>
      </li>
    </ul>
  )
}

export default SiteMainMenu
